import * as React from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import config from '../../utils/config';
import Axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useTranslate, TranslatorProvider } from 'react-translate';
import getTranslation from '../../constants/translations';
import { FaWhatsapp } from 'react-icons/all';

const initialState = { status: 'clean' };

function reducer(state, action) {
  switch (action.type) {
    case 'valid':
      return { status: (state.status = 'valid') };
    case 'inValid':
      return { status: (state.status = 'inValid') };
    case 'clean':
      return { status: (state.status = 'clean') };
    case 'complete':
      return { status: (state.status = 'complete') };
    default:
      throw new Error();
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const HomeScreen = () => {
  const [working, setWorking] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [data, setData] = React.useState({
    code1: undefined,
    code2: undefined
  });
  const [name, setName] = React.useState('');
  const [showFine, setShowFine] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  let query = useQuery();
  const project = query.get('project');
  const lang = query.get('lang');
  const old = query.get('old');
  const [isOldForm] = React.useState(!!old);
  config.headers.project = project ?? config.headers.project;

  React.useEffect(() => {
    if (showError) {
      setData({
        code1: undefined,
        code2: undefined
      });
      dispatch({ type: 'complete' });
    }
  }, [showError]);

  const handleValidateFirst = (m, t) => {
    setWorking(true);
    setShowError(false);
    setShowFine(false);
    const uri = `${config.host}codes/${data.code1}`;
    Axios.get(uri, {
      headers: config.headers
    })
      .then(response => {
        setWorking(false);
        if (isOldForm) {
          dispatch({ type: 'valid' });
        } else {
          handleValidateSecond(m);
        }
        //
      })
      .catch(error => {
        setErrorMsg(error.response?.data?.detail ?? m('DefaultErr'));
        setWorking(false);
        setShowError(true);
        console.log(error.response.data.detail);
      });
  };

  const handleValidateSecond = m => {
    setWorking(true);
    setShowError(false);
    setShowFine(false);
    const uri = `${config.host}validation`;
    const body = {
      code: data.code1,
      serie: data.code1
    };
    Axios.post(uri, body, {
      headers: config.headers
    })
      .then(response => {
        setShowFine(true);
        setWorking(false);
        // setData({
        //   code1: undefined,
        //   code2: undefined
        // });
        dispatch({ type: 'complete' });
      })
      .catch(error => {
        setErrorMsg(error.response?.data?.detail ?? m('DefaultErr'));
        setWorking(false);
        setShowError(true);
        console.log(error);
      });
  };

  const handleValidateSecondOld = m => {
    console.log('handleValidateSecondOld');
    setWorking(true);
    setShowError(false);
    setShowFine(false);
    const uri = `${config.host}validation`;
    const body = {
      code: data.code1,
      serie: data.code2
    };
    Axios.post(uri, body, {
      headers: config.headers
    })
      .then(response => {
        setShowFine(true);
        setWorking(false);
        // setData({
        //   code1: undefined,
        //   code2: undefined
        // });
        dispatch({ type: 'complete' });
      })
      .catch(error => {
        setErrorMsg(error.response?.data?.detail ?? m('DefaultErr'));
        setWorking(false);
        setShowError(true);
        console.log(error);
      });
  };

  const onValueChange = val => {
    setData(prevState => {
      return { ...prevState, ...val };
    });
  };

  const mySubmitHandler = event => {
    event.preventDefault();
    handleValidateFirst();
  };

  const mySubmitHandler2 = event => {
    event.preventDefault();
    handleValidateSecondOld();
  };

  const sendWhatsapp = t => {
    const msg = t('WhatsappMsg', { name: name, code: data.code1 });
    console.log(msg);
    const uri = encodeURI('https://wa.me/526863918450?text=' + msg);
    window.open(uri, '_blank');
  };

  const RenderMsg = () => {
    let t = useTranslate('Msg');
    let h = useTranslate('Home');
    if (showError) {
      return (
        <div
          style={{
            maxHeight: 150,
            backgroundColor: '#ff0000',
            color: '#ffffff',
            justifyContent: 'center',
            borderRadius: 10,
            flex: 1,
            fontSize: 16,
            padding: 10
          }}>
          <span>{t('ErrorMsg', { errorMsg: errorMsg })}</span>
        </div>
      );
    }
    if (showFine) {
      return (
        <div>
          <div
            style={{
              maxHeight: 350,
              backgroundColor: '#22bb33',
              color: '#ffffff',
              justifyContent: 'center',
              borderRadius: 10,
              flex: 1,
              fontSize: 16,
              padding: 10
            }}>
            <span>{t('FineMsg')}</span>
          </div>
          <Form.Group controlId="name">
            <Form.Label>{h('form3')}</Form.Label>
            <Form.Control
              placeholder={h('form3PlaceHolder')}
              autoFocus
              value={name}
              onChange={val => setName(val.target.value)}
            />
          </Form.Group>
          <Button
            style={{ marginTop: 10 }}
            variant="outline-success"
            type="button"
            block
            disabled={!name}
            onClick={() => sendWhatsapp(t)}>
            {working ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <div>
                {h('form3Button')}
                <FaWhatsapp style={{ paddingLeft: 5 }} />
              </div>
            )}
          </Button>
        </div>
      );
    }
    return null;
  };

  const RenderFormOld = () => {
    let t = useTranslate('Home');
    let m = useTranslate('Msg');
    switch (state.status) {
      case 'clean':
        return (
          <Form onSubmit={mySubmitHandler}>
            <Form.Group controlId="firstCode">
              <Form.Label>{t('form1')}</Form.Label>
              <Form.Control
                placeholder={t('form1PlaceHolder')}
                autoFocus
                value={data.code1}
                onChange={val => onValueChange({ code1: val.target.value })}
              />
            </Form.Group>
            <RenderMsg />
            <Button
              style={{ marginTop: 10 }}
              variant="danger"
              type="button"
              block
              disabled={!data.code1}
              onClick={() => (!working ? handleValidateFirst(m, t) : null)}>
              {working ? <Spinner animation="border" role="status"></Spinner> : t('form1Button')}
            </Button>
          </Form>
        );

      case 'valid':
        return (
          <Form onSubmit={mySubmitHandler2}>
            <Form.Group controlId="secondCode">
              <Form.Label>{t('form2PlaceHolder')}</Form.Label>
              <Form.Control
                placeholder={t('form2PlaceHolder')}
                autoFocus
                value={data.code2}
                onChange={val => onValueChange({ code2: val.target.value })}
              />
            </Form.Group>
            <RenderMsg />
            <Button
              style={{ marginTop: 10 }}
              variant="danger"
              type="button"
              block
              disabled={!data.code2}
              onClick={() => (!working ? handleValidateSecondOld(m) : null)}>
              {working ? <Spinner animation="border" role="status"></Spinner> : t('form2Button')}
            </Button>
          </Form>
        );

      case 'complete':
        return <RenderMsg />;

      default:
        return <div />;
    }
  };

  const RenderForm = () => {
    let t = useTranslate('Home');
    let m = useTranslate('Msg');
    switch (state.status) {
      case 'clean':
        return (
          <Form onSubmit={mySubmitHandler}>
            <Form.Group controlId="firstCode">
              <Form.Label>{t('form1')}</Form.Label>
              <Form.Control
                placeholder={t('form1PlaceHolder')}
                autoFocus
                value={data.code1}
                onChange={val => onValueChange({ code1: val.target.value })}
              />
            </Form.Group>
            <RenderMsg />
            <Button
              style={{ marginTop: 10 }}
              variant="danger"
              type="button"
              block
              disabled={!data.code1}
              onClick={() => (!working ? handleValidateFirst(m, t) : null)}>
              {working ? <Spinner animation="border" role="status"></Spinner> : t('form1Button')}
            </Button>
          </Form>
        );

      case 'complete':
        return <RenderMsg />;

      default:
        return <div />;
    }
  };

  return (
    <TranslatorProvider translations={getTranslation(lang)}>
      <div id="content">{isOldForm ? <RenderFormOld /> : <RenderForm />}</div>
    </TranslatorProvider>
  );
};

export default HomeScreen;
