import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomeScreen from '../components/screens/Home';

const AppNavigator = () => {
  // let query = useQuery();
  // // let query = useParams();
  // React.useEffect(() => {
  //   console.log('query', query);
  // }, [query]);

  return (
    <Router>
      <div id="app">
        {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/home:project" children={<HomeScreen />} />
          <Route path="/">
            <HomeScreen />
          </Route>
          <Route
            render={function () {
              return <p>Not found</p>;
            }}
          />
        </Switch>
      </div>
    </Router>
  );
};

export default AppNavigator;
