import config from '../utils/config';

const getTranslation = lang => {
  if (lang === 'es') {
    config.headers['Accept-Language'] = lang;
    return {
      locale: 'es-mx',
      Home: {
        form1: 'Valida tu producto',
        form1PlaceHolder: 'Código de serie',
        form1Button: 'Validar',
        form2: 'Código secreto',
        form2PlaceHolder: 'Código de validación',
        form2Button: 'Validar código',
        form3: 'Felicidades ya validaste tu producto! ahora registrate y obten beneficios',
        form3PlaceHolder: 'Ingresa tu nombre',
        form3Button: 'Registrarme'
      },
      Msg: {
        FineMsg: 'Validación exitosa! Tu producto es Auténtico!',
        ErrorMsg: '{{errorMsg}}',
        DefaultErr: 'Código no válido. Intenta nuevamente o contáctanos',
        WhatsappMsg: 'Hola, mi nombre es {{name}} y registré este código {{code}}'
      }
    };
  }
  if (lang === 'en') {
    config.headers['Accept-Language'] = lang;
    return {
      locale: 'en',
      Home: {
        form1: 'Validate your product',
        form1PlaceHolder: 'Serial code',
        form1Button: 'Validate',
        form2: 'Secret code',
        form2PlaceHolder: 'Validation code',
        form2Button: 'Validate code',
        form3: 'Congratulations you have already validated your product! now register and get benefits',
        form3PlaceHolder: 'Name',
        form3Button: 'Register'
      },
      Msg: {
        FineMsg: 'Successful validation! Your product is Authentic!',
        ErrorMsg: '{{errorMsg}}',
        DefaultErr: 'Invalid Code. Try again or contact us',
        WhatsappMsg: 'Hello, my name is {{name}} and I registered this code {{code}}'
      }
    };
  }
  return {
    locale: 'es-mx',
    Home: {
      form1: 'Valida tu producto',
      form1PlaceHolder: 'Código de serie',
      form1Button: 'Validar',
      form2: 'Código secreto',
      form2PlaceHolder: 'Código de validación',
      form2Button: 'Validar código secreto',
      form3: 'Felicidades ya validaste tu producto! ahora registrate y obten beneficios',
      form3PlaceHolder: 'Ingresa tu nombre',
      form3Button: 'Registrarme'
    },
    Msg: {
      FineMsg: 'Validación exitosa! Tu producto es Auténtico!',
      ErrorMsg: '{{errorMsg}}',
      DefaultErr: 'Código no válido. Intenta nuevamente o contáctanos',
      WhatsappMsg: 'Hola, mi nombre es {{name}} y registré este código {{code}}'
    }
  };
};

export default getTranslation;
